<template>
  <localized-link :to="to || ''">
    <slot />
  </localized-link>
</template>

<script>
export default {
  name: "Alink",
  props: {
    to: [String, Object]
  }
};
</script>

<style scoped></style>
